import { Button } from 'antd';
import { Link } from 'gatsby';
import React from 'react';
import BasketMinusIcon from '../../icons/basket-minus';
const OrderEmpty = () => {
  return (
    <div className="w-full flex items-center flex-col p-6">
      <BasketMinusIcon />
      <span className="text-light-secondary mt-[22px]">
        ไม่พบคำสั่งซื้อของคุณ กลับไปดูสินค้าเพื่อเริ่มสั่งซื้อได้เลย
      </span>
      <Link to="/products" className="mt-6">
        <Button type="primary">ดูสินค้าทั้งหมด</Button>
      </Link>
    </div>
  );
};

export default OrderEmpty;
