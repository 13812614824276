import React from 'react';

export const BasketMinusIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46 36.0001V40.0001H30V36.0001H46ZM46 20.0001L45.92 20.5801L44 27.6001C42.1747 26.5497 40.1053 25.9978 37.9994 25.9997C35.8935 26.0015 33.8251 26.5571 32.0017 27.6106C30.1782 28.6642 28.6639 30.1787 27.6105 32.0022C26.5571 33.8257 26.0017 35.8941 26 38.0001C26 39.4001 26.26 40.7401 26.7 42.0001H11C9.44 42.0001 8.08 41.1001 7.42 39.8001L2.2 20.8801L2 20.0001C2 18.9001 2.9 18.0001 4 18.0001H13.16L22.36 4.86006C22.5444 4.59181 22.7917 4.37278 23.0802 4.2221C23.3687 4.07142 23.6898 3.99367 24.0153 3.99564C24.3408 3.99761 24.6609 4.07925 24.9476 4.23341C25.2343 4.38758 25.4789 4.60959 25.66 4.88006L34.84 18.0001H44C45.1 18.0001 46 18.9001 46 20.0001ZM28 30.0001C28 27.8001 26.22 26.0001 24 26.0001C21.78 26.0001 20 27.8001 20 30.0001C20 32.2001 21.8 34.0001 24 34.0001C26.2 34.0001 28 32.2201 28 30.0001ZM30 18.0001L24 9.48006L18 18.0001H30Z"
      fill="url(#paint0_linear_130_10352)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_130_10352"
        x1="2"
        y1="3.53715"
        x2="21.4287"
        y2="43.6344"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0F172A" stop-opacity="0.06" />
        <stop offset="1" stop-color="#0F172A" stop-opacity="0.15" />
      </linearGradient>
    </defs>
  </svg>
);

export default BasketMinusIcon;
