import { Avatar, List, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useOrder } from '../../hooks/use-order';
import { Order } from '../../types/Order';
import AccountLayout from '../account/AccountLayout';
import Breadcrumb from '../Breadcrumb';
import MainLayout from '../layout/MainLayout';
import Section from '../Section';
import { DateTime } from 'luxon';
import { formatPrice } from '../../utils/format-price';
import { Link } from 'gatsby';
import OrderItem from './OrderItem';
import BasePagination from '../pagination/BasePagination';
import OrderStatusTag from './OrderStatus';
import OrderEmpty from './OrderEmpty';

const { Text } = Typography;

const OrderHistoryPage = () => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    pageSize,
    actions: { listOrders },
  } = useOrder();

  const fetchOrder = async (offset?: number) => {
    try {
      setLoading(true);
      const res = await listOrders(offset);
      setOrders(res.orders);
      setTotal(res.count);
    } catch (_) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  const onChangePage = (page: number) => {
    setCurrentPage(page);
    fetchOrder((page - 1) * pageSize);
  };

  return (
    <MainLayout>
      <Breadcrumb
        items={[
          {
            title: 'คำสั่งซื้อ',
            link: '/orders',
          },
        ]}
        title="ประวัติการสั่งซื้อ"
      />
      <Section className="py-6">
        <AccountLayout>
          <div className="p-6 bg-neutral-1 rounded-[6px] shadow-small border-light-divider border">
            {orders.length > 0 || loading ? (
              <>
                <Table
                  pagination={false}
                  scroll={{ x: true }}
                  loading={loading}
                  rowKey={(record) => record.id}
                  dataSource={orders}
                  expandable={{
                    expandRowByClick: true,
                    expandedRowRender: (record) => (
                      <div className="bg-light-lighter border border-t-0 border-light-divider rounded-[6px]">
                        <List
                          dataSource={record.items}
                          renderItem={(item) => {
                            return <OrderItem item={item} />;
                          }}
                        />
                      </div>
                    ),
                  }}
                  columns={[
                    {
                      title: 'เลขที่คำสั่งซื้อ',
                      dataIndex: 'display_id',
                      key: 'display_id',
                      render: (value: number) =>
                        `#${`${value}`?.padStart(7, '0')}`,
                    },
                    {
                      title: 'สั่งซื้อโดย',
                      dataIndex: 'customer',
                      key: 'customer',
                      render: (value) => (
                        <>
                          <Avatar>
                            {value.first_name.substring(0, 1).toUpperCase()}
                          </Avatar>
                          <Text className="ml-3 !text-sm !text-light-title">
                            {`${value.first_name}`}
                          </Text>
                        </>
                      ),
                    },
                    {
                      title: 'จำนวนสินค้า',
                      dataIndex: 'items',
                      key: 'items',
                      render: (value) =>
                        value
                          .map((i) =>
                            i.fulfilled_quantity
                              ? i.fulfilled_quantity
                              : i.quantity
                          )
                          .reduce((acc, quantity) => acc + quantity),
                    },
                    {
                      title: 'วันที่สั่งซื้อ',
                      dataIndex: 'created_at',
                      key: 'date',
                      align: 'center',
                      render: (value) =>
                        DateTime.fromISO(value).toFormat('d LLL yy'),
                    },
                    {
                      title: 'เวลาที่สั่งซื้อ',
                      dataIndex: 'created_at',
                      key: 'time',
                      align: 'center',
                      render: (value) =>
                        DateTime.fromISO(value).toFormat('HH:mm'),
                    },
                    {
                      title: 'สถานะ',
                      dataIndex: 'status',
                      key: 'status',
                      render: (value) => <OrderStatusTag value={value} />,
                    },
                    {
                      title: 'ราคารวม',
                      dataIndex: 'total',
                      key: 'total',
                      align: 'right',
                      render: (value, record) => (
                        <>
                          {record.items.some((item) => !item.unit_price) && (
                            <span className="text-red-6">*</span>
                          )}
                          {`฿ ${formatPrice(value)} THB`}
                        </>
                      ),
                    },
                    {
                      title: '',
                      dataIndex: 'display_id',
                      key: 'view',
                      render: (value) => (
                        <Link
                          className="!font-semibold"
                          to={`/orders/${value}`}
                        >
                          View
                        </Link>
                      ),
                    },
                  ]}
                />
                <BasePagination
                  total={total}
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={onChangePage}
                />
              </>
            ) : (
              <OrderEmpty />
            )}
          </div>
        </AccountLayout>
      </Section>
    </MainLayout>
  );
};

export default OrderHistoryPage;
